import { Box } from "@mui/material";

// Components for Home
import Banner from "../components/Banner";
import CategoryCard from "../components/CategoryCard";
import iconCat from "../assets/img/home/Open_Broadcaster_Software_Logo1.png";
import categoryOne from "../assets/img/home/CardImage.png";
import categorytwo from "../assets/img/home/CardImage2.png";
import categorythree from "../assets/img/home/CardImage3.png";
import categoryfour from "../assets/img/home/CardImage4.png";

const HomePage = () => {

    const title = '<span className="color-apple">OBS</span> Extension';
    const text = "Polar Ace's OBS extension allows us to display Advertisements, moving images, other live streams in a designated part of a live stream. It can be turned on and off by the creator, it can pull massive amounts of live data in real time, and may have other uses. Someone can go live in a box within your live using this free product! ";
    
    const title2 = 'Match with Potential <span className="color-apple">Sponsors</span>';
    const text2 = "Polar Ace is a fully automated platform designed to help connect content creators with brands. We use your data and a complex formula to match you with content creators and obtain the most lucrative sponsorships. No obligation to accept anything.";
    
    const title3 = 'Archive Your <span className="color-apple">Chat!</span>';
    const text3 = "Polar Ace gives content creators the option to archive their entire live stream chats indefinitely, no matter the size of the chat, the number of messages, or the number of live streams. Absolutely free! ";
    
    const title4 = 'Track Your <span className="color-apple">Data!</span>';
    const text4 = "Polar Ace collects as much data as possible with your permission and displays it for you in your user dashboard! This information can help you track your growth, revenue, and areas to improve. ";

    return (
        <Box className="w-100 home pt-5">
            <section className="col-12 section-banner py-5">
                <Banner />
            </section>

            <section className="col-12 section-categories py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="w-100 pe-md-1 pb-4">
                                <CategoryCard 
                                    icon={iconCat} 
                                    image={categoryOne} 
                                    direction={'horizontal'} 
                                    title={title}
                                    text={text}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 col-12">
                            <div className="pb-4 h-100">
                                <CategoryCard 
                                    image={categorytwo} 
                                    direction={'vertical'} 
                                    title={title2}
                                    text={text2}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="px-md-1 pb-4 h-100">
                                <CategoryCard 
                                    image={categorythree} 
                                    direction={'vertical'} 
                                    title={title3}
                                    text={text3}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="pb-4 ps-md-1 h-100">
                                <CategoryCard 
                                    image={categoryfour} 
                                    direction={'vertical'} 
                                    title={title4}
                                    text={text4}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Box>
    );
}

export default HomePage;