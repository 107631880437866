import React, { useEffect, useState } from 'react';
import "../assets/css/dashboard.css";
import SidebarDashboard from './dashboardComponents/SidebarDashboard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LayersIcon from '@mui/icons-material/Layers';
import ProfileImage from "../assets/img/dashboard/profile-image.png";
import ProfileDropdown from '../components/Modals/ProfileDropdown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ProfileImg from "../assets/img/dashboard/profile.png";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';

import ModalLogOut from '../components/Modals/ModalLogOut';
import ModalNotifications from '../components/Modals/ModalNotifications';

const DashboardLayout = ({ children }) => {
  const [openNotification, setOpenNotification] = useState(false);
  const handleOpenNotification = () => setOpenNotification(true);
  const handleCloseNotification = () => setOpenNotification(false);
  
  const [openLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const onSubmitLogout = (data) => {
    console.log(data);
    if (data.error) {
    }
    if (!data.error) {
      window.location.href = "/";
    }
    setOpenLogout(false);
  }

  useEffect(() => {
    document.body.classList.add('body');
    document.body.classList.add('dashboardPage');
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [activeMenu, setActiveMenu] = useState(false);

  const onCloseMenu = () => {
    setActiveMenu(false);
  }

  return (
    <div className='col-12 d-flex'>
      <aside className={`aside-dashboard animate__animated animate__fadeInDown ${activeMenu ? "active":""}`}>
        <SidebarDashboard onClose={onCloseMenu} />
      </aside>
      <main className='main-dashboard'>
        <header className='header-dashboard w-100'>
          <div className='col-12 d-flex justify-content-between align-items-center pb-3'>
            <div onClick={() => setActiveMenu(true)} className='toggle-menu'>
              <MenuIcon />
            </div>
            <div className='d-md-block d-none'>
              <h2>Welcome, Dereck!</h2>
              <p>Check out latest updates.</p>
            </div>
            <div className='d-flex gap-2 align-items-center'>
              <div>
                <button onClick={handleOpenNotification} className='btn btn-hdashboard btn-hdashboard__notify'>
                  <span className='notify'>
                    <NotificationsIcon />
                  </span>
                </button>
              </div>
              <div>
                <a className='btn btn-hdashboard btn-hdashboard__layers' href="/#">
                  <LayersIcon />
                </a>
              </div>
              <div>
                <button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  className='btn btn-hdashboard btn-hdashboard__profile'
                >
                  <span className='icon-profile'>
                    <img src={ProfileImage} alt="lg" />
                  </span>
                  <ExpandMoreIcon />
                </button>

                <Menu
                  className='dropdown-menu__dashboard'
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem className='menu-item' onClick={handleClose}>
                    <div className='d-flex menu-item-profile'>
                      <div className='menu-profile__image'>
                        <img src={ProfileImg} alt="Profile" />
                      </div>
                      <div className='menu-profile__info'>
                        <p className='menu-profile__name'>Derek Dobosz</p>
                        <p className='menu-profile__email'>derekdummy@gmail.com</p>
                        <a className='menu-profile__link' href='/my-profile'>View & Edit profile</a>
                      </div>
                    </div>
                  </MenuItem>
                  <MenuItem className='menu-item' onClick={() => window.location.href = "/set-payout-method"}>
                    <span className='border-bottom'>
                      <CreditCardIcon className='icon' />
                      Set Payout Method
                    </span>
                  </MenuItem>
                  <MenuItem className='menu-item' onClick={() => window.location.href = "/settings"}>
                    <span className='border-bottom'>
                      <SettingsIcon className='icon' />
                      Settings
                    </span>
                  </MenuItem>
                  <MenuItem className='menu-item' onClick={handleOpenLogout}>
                    <span className='border-bottom'>
                      <LogoutIcon className='icon' />
                      Log Out
                    </span>
                  </MenuItem>
                </Menu>

                <ProfileDropdown />
              </div>
            </div>
          </div>
        </header>
        {children}
      </main>

      <ModalLogOut open={openLogout} handleClose={handleCloseLogout} onSubmit={onSubmitLogout} />
      <ModalNotifications open={openNotification} handleClose={handleCloseNotification} />
    </div>
  )
};

export default DashboardLayout;
