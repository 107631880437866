import React, { useState, useRef } from "react";

import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PublicIcon from '@mui/icons-material/Public';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import LayersIcon from '@mui/icons-material/Layers';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

import Picture from "../../assets/img/my-profile/profile.png";
import UploadImage from "../../assets/img/my-profile/upload-image.png";

import "../../assets/css/MyProfile/index.css";

const MyProfilePage = () => {
    const [editView, setEditView] = useState(false);
    const [imageSrc, setImageSrc] = useState(Picture);

    const fileInputRef = useRef(null);

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageSrc(reader.result);
          };
          reader.readAsDataURL(file);
        }
    };

    return (
        <section className="section-my_profile">
            <h1 className="title-dashboard">My Profile</h1>

            <div className="col-12 rounded-2 card-sponsor my-profile-info">
                <div className="d-flex flex-md-row flex-column">
                    <div className="my-profile__picture mb-3 text-center">
                        <img src={imageSrc} alt="Picture" />

                        <button type="button" className={`btn btn-remove-image ${editView ? "d-flex":"d-none"}`}>
                            Remove
                        </button>
                        <button type="button" onClick={handleClick} className={`btn btn-upload-image ${editView ? "d-flex":"d-none"}`}>
                            <img src={UploadImage} alt="Upload Image" />
                        </button>

                        <input type="file" onChange={handleFileChange} id="file" ref={fileInputRef} accept="image/*" className="d-none" />
                    </div>
                    <div className={`${editView ? "d-flex":"d-none"} my-profile__info ps-md-4 flex-column-reverse flex-xl-row justify-content-end`}>
                        <div className="login-form no-box w-100 pe-xl-4">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Nombre</label>
                                <input type="text" className={`form-control m-0`} id="name" placeholder="Enter your name" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="birth" className="form-label">Date of Birth</label>
                                <input type="date" className={`form-control m-0`} id="birth" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="gender" className="form-label">Gender</label>
                                <select className={`form-control m-0`} id="gender">
                                    <option value="">Select..</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="country" className="form-label">Country</label>
                                <select className={`form-control m-0`} id="country">
                                    <option value="">Select..</option>
                                    <option value="usa">Usa</option>
                                </select>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label htmlFor="city" className="form-label">City</label>
                                        <input type="text" className={`form-control m-0`} id="city" placeholder="Enter your city" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-3">
                                        <label htmlFor="zipcode" className="form-label">Zipcode</label>
                                        <input type="text" className={`form-control m-0`} id="zipcode" placeholder="Enter your zipcode" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input type="text" className={`form-control m-0`} id="address" placeholder="Enter your address" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="language" className="form-label">Broadcasting Language</label>
                                <select className={`form-control m-0`} id="language">
                                    <option value="">Select...</option>
                                    <option value="english">English</option>
                                </select>
                            </div>
                            
                            <div className="mb-3">
                                <label htmlFor="about" className="form-label">About</label>
                                <textarea className={`form-control m-0`} id="about" rows={3} />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="job" className="form-label">Job Title</label>
                                <input type="text" className={`form-control m-0`} id="job" placeholder="" />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="tier" className="form-label">Tier</label>
                                <select className={`form-control m-0`} id="tier">
                                    <option value="">Select...</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="category" className="form-label">Category</label>
                                <select className={`form-control m-0`} id="category">
                                    <option value="">Select...</option>
                                    <option value="Gaming">Gaming</option>
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="goals" className="form-label">Goals</label>
                                <textarea className={`form-control m-0`} id="goals" rows={3} />
                            </div>

                        </div>
                        <div className="mb-3">
                            <div className="d-flex gap-3 justify-content-between">
                                <button onClick={() => setEditView(false)} type="button" className="btn btn-dash-secondary">
                                    Cancel
                                </button>
                                <button onClick={() => setEditView(false)} type="button" className="btn btn-dash-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`${editView ? "d-none":"d-block"} my-profile__info ps-md-4`}>
                        <div className="d-flex justify-content-between flex-md-row flex-column-reverse">
                            <div>
                                <h1>Dereck Dobosz</h1>
                                <p className="email">derekdummy@gmail.com</p>
                            </div>
                            <div>
                                <button onClick={() => setEditView(true)} type="button" className="btn btn-dash-secondary d-flex align-items-center justify-content-center px-3 gap-2">
                                    <EditIcon style={{ fontSize: "16px" }} />
                                    Edit
                                </button>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-xl-3 col-lg-6 col-12">
                                    <div className="data-profile">
                                        <CalendarMonthIcon />
                                        <div className="data">
                                            <h6>
                                                Date of Birth
                                            </h6>
                                            <p>15 July, 1998</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-12">
                                    <div className="data-profile">
                                        <CalendarMonthIcon />
                                        <div className="data">
                                            <h6>Gender</h6>
                                            <p>Male</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-12">
                                    <div className="data-profile">
                                        <PublicIcon />
                                        <div className="data">
                                            <h6>
                                                Country
                                            </h6>
                                            <p>United States</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-12">
                                    <div className="data-profile">
                                        <LocationCityIcon />
                                        <div className="data">
                                            <h6>
                                                City
                                            </h6>
                                            <p>Los Angeles</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-12">
                                    <div className="data-profile">
                                        <CallToActionIcon />
                                        <div className="data">
                                            <h6>ZIp Code</h6>
                                            <p>85684</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-12">
                                    <div className="data-profile">
                                        <GTranslateIcon />
                                        <div className="data">
                                            <h6>
                                                Address
                                            </h6>
                                            <p>Lorem Ipsum issimply dummy text of</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-12">
                                    <div className="data-profile">
                                        <GTranslateIcon />
                                        <div className="data">
                                            <h6>
                                                Broadcasting language
                                            </h6>
                                            <p>English</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-gray-secondary rounded-3 p-3 mb-3">
                            <h5>About</h5>
                            <p className="fs-14 text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text when an unknown printer took...</p>
                        </div>

                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-12">
                                <div className="data-profile">
                                    <LayersIcon />
                                    <div className="data">
                                        <h6>
                                            Tier
                                        </h6>
                                        <p>5</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12">
                                <div className="data-profile">
                                    <SportsEsportsIcon />
                                    <div className="data">
                                        <h6>
                                            Category
                                        </h6>
                                        <p>Gaming</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-gray-secondary rounded-3 p-3 mb-3">
                            <h5>Job Title</h5>
                            <p className="fs-14 text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>

                        <div className="bg-gray-secondary rounded-3 p-3 mb-3">
                            <h5>Goals</h5>
                            <p className="fs-14 text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text when an unknown printer took...</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MyProfilePage;