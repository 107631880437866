import * as React from 'react';
import {
    Box,
    Modal,
} from '@mui/material';

import BankIcon from "../../assets/img/payout/bankIcon.png";

import "../../assets/css/payout/index.css";

export default function ModalSetPayout({open, handleClose, onSubmit}) {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-polar">
                <div className='modal-polar__header text-center'>
                    <div className='icon-polar'>
                        <img src={BankIcon} alt="walletIcon" />
                    </div>
                    <h3 className='mt-3'>Connect Bank Account</h3>
                    <p className='fs-14'>Enter Bank details.</p>
                </div>
                <div className='modal-polar__body'>
                    <div className='w-100 login-form no-box'>
                        <div className="mb-3">
                            <label htmlFor="account" className="form-label">Account Holder Name</label>
                            <input type="text" className={`form-control m-0`} id="account" placeholder="Account Holder Name" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="bank" className="form-label">Select Bank Name</label>
                            <select className={`form-control m-0`} id="bank">
                                <option value="">Select</option>
                            </select>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="IBAN" className="form-label">IBAN</label>
                                    <input type="text" className={`form-control m-0`} id="IBAN" placeholder="IBAN" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="BIC" className="form-label">Bank BIC/SWIFT CODE</label>
                                    <input type="text" className={`form-control m-0`} id="BIC" placeholder="Bank BIC/SWIFT CODE" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="IDNumber" className="form-label">ID Number</label>
                                    <input type="text" className={`form-control m-0`} id="IDNumber" placeholder="ID Number" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="SNN" className="form-label">SNN Number</label>
                                    <input type="text" className={`form-control m-0`} id="SNN" placeholder="SNN Number" />
                                </div>
                            </div>
                        </div>
                        
                        <div className='w-100 d-flex align-items-center justify-content-center gap-2 mt-3'>
                            <button onClick={() => onSubmit({error: true, message: "Ha ocurrido un error"})} type="button" className="btn btn-dash-secondary">
                                Cancel
                            </button>
                            <button onClick={() => onSubmit({error: false, message: "Ok!"})} type="button" className="btn btn-dash-primary">
                                Connect
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
