import React, { useState } from 'react';


import { 
  styled,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Radio,
} from '@mui/material';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';


import ErrorIcon from '@mui/icons-material/Error';
import Logo from '../../assets/img/logo.png';
import LogoInfluencer from '../../assets/img/sign-up/icon-influencer.png';
import LogoAdvertiser from '../../assets/img/sign-up/icon-Advertiser.png';
import Box from '@mui/material/Box';

import "../../assets/css/sign-up/index.css";
import 'animate.css';


const SignUp = () => {
  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} style={{
      border: props.checked ? '1.5px solid #D7E926' : '1.5px solid transparent'
    }} />
  ))(({ theme, checked }) => ({
    ...(checked && {
      '.MuiFormControlLabel-label': {
        color: '#fff',
      },
    }),
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return (
      <StyledFormControlLabel
        checked={checked}
        {...props}
        label={
          <Box width='100%' display="flex" alignItems="center">
            <span className="icon-label">
              <img src={props.icon} alt="Influencer" />
            </span>
            {props.label}
          </Box>
        }
      />
    );
  }

  const [steps, setSteps] = useState(1);
  const [showStep1, setShowStep1] = useState(true);
  const [showStep2, setShowStep2] = useState(false);

  const stepContinue = (step) => {
    if (step === 1) {
      setSteps(2);
      setTimeout(() => {
        setShowStep1(false);
        setShowStep2(true);
      }, 1000);
    }
  }


  const [error, setError] = useState({
      'password': false,
      'email': false,
  });
  const [valueEmail, setValueEmail] = useState('');
  const [valuepassword, setValuepassword] = useState('');
  
  const onSubmit = (event) => {
      event?.preventDefault();

      setError({
          'password': false,
          'email': false,
      }); 
      
      if (valueEmail === '') {
          setError(prevError => ({
              ...prevError,
              email: true
          }));
      }
      
      if (valuepassword === '') { 
          setError(prevError => ({
              ...prevError,
              password: true
          }));
      }

      if (valueEmail === '' || valuepassword === '') {
          return;
      }

      window.location.href = "/complete-register";
  }

  return (
    <div className='body singlePages'>
      <div className='col-12 px-4' style={{
             minHeight: "100vh",
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
      }}>
        <form className='w-100' onSubmit={onSubmit}>
          <div className={`col-lg-6 col-xl-4 col-12 mx-auto login-form ${steps !== 1 ? 'animate__animated animate__fadeOutLeft' : ''} ${!showStep1 ? 'd-none' : ''} `} >
            <div className='from-sign-up__Radio-group d-flex flex-column align-items-center gap-1 my-2 w-100'>
              <img src={Logo} alt="/#" />
              <h1>Create your account</h1>
              <p>Continue as a</p>
            </div>
            <div className='d-flex flex-column align-items-center'>
              <RadioGroup className='group-label-polar' name="use-radio-group" defaultValue="first">
                <MyFormControlLabel name="typeAccount" className='form-control__label p-4'
                  value="Influencer" label="Influencer" icon={LogoInfluencer} control={<Radio />} />

                <MyFormControlLabel name="typeAccount" className='form-control__label p-4'
                  value="Advertiser" label="Advertiser" icon={LogoAdvertiser} control={<Radio />} />
              </RadioGroup>
            </div>
            <div className='d-flex align-items-center justify-content-center mt-4'>
              <button type='button' onClick={() => stepContinue(1)} className='w-50 btn btn-primary px-3 py-4'>
                Continue
              </button>
            </div>
            <div className='d-flex justify-content-center fs-6 gap-1 mt-3'>
              <p>
                Already have an account?
                <a className='ps-1 color-apple' href="/login">Log in</a>
              </p>
            </div>
          </div>

          <div className={`col-lg-6 col-xl-4 col-12 mx-auto login-form ${steps !== 2 ? 'animate__animated animate__fadeOutLeft' : 'animate__animated animate__fadeInRight'} ${!showStep2 ? 'd-none' : ''}`} >
            <div className='from-sign-up__Radio-group d-flex flex-column align-items-center gap-1 my-2 w-100'>
              <img src={Logo} alt="/#" />
              <h1>Create your account</h1>
            </div>
            <div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input onChange={(event) => setValueEmail(event?.target.value)} type="email" className={`form-control ${error.email ? 'error' : 'primary'}`} id="email" placeholder="Enter your email" />
                {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>}
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input onChange={(event) => setValuepassword(event?.target.value)} type="password" className={`form-control ${error.password ? 'error' : 'primary'}`} id="password" placeholder="Enter password" />
                {error.password && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>}
              </div>
              
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                <input type="confirmPassword" className={`form-control`} id="confirmPassword" placeholder="Confirm password" />
                {error.password && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>}
              </div>

              <div className='mb-3'>
                <label className='form-label xs' >
                  <Checkbox defaultChecked />
                  I read and agree to the <span className='color-apple'>Terms of service</span> and the <span className='color-apple'>Privacy Policy</span>
                </label>
              </div>

              <div className='d-flex align-items-center justify-content-center mt-4'>
                <button type='submit' className='w-75 px-1 py-3 btn btn-primary'>
                  Sign up
                </button>
              </div>
              <div className='d-flex justify-content-center fs-6 gap-1 mt-3'>
                <p>
                  Already have an account? 
                  <a className='ps-1 color-apple' href="/login">Log in</a>
                </p>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
}

export default SignUp;
