import * as React from 'react';
import {
    Box,
    Modal,
    styled,
    FormControlLabel,
    Radio,
    RadioGroup,
    useRadioGroup
} from '@mui/material';

import walletIcon from "../../assets/img/payout/wallet-icon.png";
import BankIcon from "../../assets/img/payout/bankIcon.png";
import PaypalIcon from "../../assets/img/payout/paypalIcon.png";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import "../../assets/css/payout/index.css";

export default function ModalPayout({open, handleClose, onSubmit}) {
    
    const StyledFormControlLabel = styled((props) => (
            <FormControlLabel {...props} style={{
                width: "100%",
                border: props.checked ? '1.5px solid #D7E926' : '1.5px solid transparent'
            }} />
        ))(({ theme, checked }) => ({
        ...(checked && {
            '.MuiFormControlLabel-label': {
            color: '#fff',
            width: '100%'
            },
        }),
    }));

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();

        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
        }

        return (
            <StyledFormControlLabel
            checked={checked}
            {...props}
            label={
                <Box width='100%' display="flex" alignItems="center" justifyContent="space-between">
                    <div className='d-flex align-items-center justify-content-start w-100'>
                        <span className="icon-label">
                            <img src={props.icon} alt="Influencer" />
                        </span>
                        {props.label}
                    </div>

                    {props.helpText ? 
                        (<span className='helpText'>
                            {props.helpText}
                        </span>)
                        :(
                            <></>
                        )
                    }
                </Box>
            }
            />
        );
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-polar">
                <div className='modal-polar__header text-center'>
                    <div className='icon-polar'>
                        <img src={walletIcon} alt="walletIcon" />
                    </div>
                    <h3 className='my-3'>Withdraw</h3>
                </div>
                <div className='modal-polar__body'>
                    <div className='w-100 form-login no-box'>
                        <div className="mb-3">
                            <label htmlFor="amount" className="form-label">Amount</label>
                            <div className='adorment-input'>
                                <input type="text" className={`form-control m-0`} id="amount" placeholder="Enter Amount" />
                                <AttachMoneyIcon />
                            </div>
                        </div>
                        <div className="mb-2">
                            <label htmlFor="method" className="form-label">Select Payout Method</label>
                            <RadioGroup className='group-label-polar' name="use-radio-group" defaultValue="first">
                                <MyFormControlLabel name="typeAccount" className='form-control__label px-4 py-2 w-100'
                                value="Influencer" label="Bank Account" icon={BankIcon} helpText="Bank of America - XX3589" control={<Radio />} />

                                <MyFormControlLabel name="typeAccount" className='form-control__label px-4 py-2 w-100'
                                value="Advertiser" label="PayPal" icon={PaypalIcon} control={<Radio />} />
                            </RadioGroup>
                        </div>
                        <div className='w-100 d-flex align-items-center justify-content-center gap-2'>
                            <button onClick={() => onSubmit({error: true, message: "Ha ocurrido un error"})} type="button" className="btn btn-dash-secondary">
                                Cancel
                            </button>
                            <button onClick={() => onSubmit({error: false, message: "Ok!"})} type="button" className="btn btn-dash-primary">
                                Withdraw
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
