import React, { useState } from "react";

import LinkIcon from '@mui/icons-material/Link';

import Background from "../../assets/img/dashboard/background-path-sidebar.png";
import ConnectTwich from "../../assets/img/connect-account/connect-twich.png";
import ConnectYoutube from "../../assets/img/connect-account/connect-youtube.png";

const ConnectAccountsPage = () => {
    const [connectYoutube, setConnectYoutube] = useState(false);
    const [connectTwich, setConnectTwich] = useState(false);

    return (
        <section className="section-connect-accounts">
            <div>
                <h1 className="title-dashboard pb-1">Connect Accounts</h1>
                <p className="fs-12">Connect your Twitch and YouTube accounts with PolarAce.</p>
            </div>
            <div className="row">
                <div className="col-md-6 col-12 mb-3">
                    <div className="bg-dash-primary rounded-3 w-100 d-flex align-items-center justify-content-center text-center" style={{
                        backgroundImage: "url("+Background+")",
                        minHeight: "600px",
                    }}>
                        <div>
                            <img className="mb-4" src={ConnectTwich} alt="Connect" />

                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <p>Connect your Twitch account<br /> with PolarAce.</p>

                                {connectTwich ? (
                                    <button onClick={() => setConnectTwich(false)} type="button" className="btn btn-dash-secondary">
                                        Disconnect
                                    </button>
                                ):(
                                    <button onClick={() => setConnectTwich(true)} type="button" className="btn btn-dash-primary d-flex align-items-center gap-2">
                                        <LinkIcon />
                                        Connect
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <div className="bg-dash-primary rounded-3 w-100 d-flex align-items-center justify-content-center text-center" style={{
                        backgroundImage: "url("+Background+")",
                        minHeight: "600px",
                    }}>
                        <div>
                            <img className="mb-4" src={ConnectYoutube} alt="Connect" />

                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <p>Connect your YouTube account <br /> with PolarAce.</p>
                                
                                {connectYoutube ? (
                                    <button onClick={() => setConnectYoutube(false)} type="button" className="btn btn-dash-secondary">
                                        Disconnect
                                    </button>
                                ):(
                                    <button onClick={() => setConnectYoutube(true)} type="button" className="btn btn-dash-primary d-flex align-items-center gap-2">
                                        <LinkIcon />
                                        Connect
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ConnectAccountsPage;