import * as React from 'react';
import {
    Box,
    Modal,
} from '@mui/material';

import successIcon from "../../assets/img/payout/success-icon.png";

import "../../assets/css/payout/index.css";

export default function ModalPayoutSuccess({open, handleClose}) {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-polar" style={{
                maxWidth: "400px",
            }}>
                <div className='modal-polar__header text-center'>
                    <div className='icon-polar error mb-3'>
                        <img src={successIcon} alt="walletIcon" />
                    </div>
                    <p className='mb-0'>Withdrawal amount of</p>
                    <h2 className='mt-2 mb-3'>$1,289</h2>
                    <h3 className='mb-4'>
                        Transferred successfully to your
                        <br />
                        bank account. 
                    </h3>
                </div>
                <div className='modal-polar__body'>
                    <div className='w-100 form-login no-box'>
                        <div className='w-100 d-flex align-items-center justify-content-center gap-2'>
                            <button onClick={handleClose} type="button" className="btn btn-dash-primary">
                                Retry
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
