import React, { useState, useRef } from 'react';
import { Transition } from 'react-transition-group';

import iconTwitch from '../../assets/img/analytics/Twitch.png'
import iconYoutube from '../../assets/img/analytics/Youtube.png'
import CardAnalytics from '../../components/AnalyticsCard/CardAnalytics';
import AnalyticsImg1 from '../../assets/img/analytics/AnalyticsCard.png';
import AnalyticsImg2 from '../../assets/img/analytics/AnalyticsCard2.png';
import AnalyticsImg3 from '../../assets/img/analytics/AnalyticsCard3.png';
import CallMadeIcon from '@mui/icons-material/CallMade';
import icons from '../../assets/img/Group 30.png';
import Card from '../../assets/img/analytics/cardanalytics.png';


import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import '../../assets/css/Analytics/index.css'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


const Analytics = () => {
    const [nextView, setNextView] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const alertRef = useRef(null);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const combinedClickHandler = () => {
        setNextView(true);
        setShowAlert(true);
        
        setTimeout(() => {
            setShowAlert(false);
        }, 10000);
    };

    return (
        <section className='w-100 section-Analytics'>

            <div className={nextView ? 'd-flex justify-content-between' : 'd-none'}>
                <div className='w-md-50 w-100'>
                    <h3 className='mt-3 mb-3'>
                        My Analytics Dat
                    </h3>
                </div>
                <div className='w-md-50 w-100 d-flex justify-content-end align-items-center pt-3'>
                    <Transition className='w-100' in={showAlert} timeout={300} nodeRef={alertRef} unmountOnExit>
                        {(state) => (
                            <Stack
                                ref={alertRef}
                                sx={{
                                    maxWidth: "420px",
                                    transition: 'opacity 300ms',
                                    opacity: state === 'entered' ? 1 : 0,
                                }}
                                spacing={2}
                            >
                                <Alert className='alert-polar' color="warning" variant="filled" severity="warning" >We are under construction and are in the process of displaying more of your data for you.</Alert>
                            </Stack>
                        )}
                    </Transition>
                </div>
            </div>


            <div className={`section-Analytics__item ${nextView ? 'd-none' : 'd-block'}`}>

                <div className='col-lg-7 col-xl-5 col-12 mx-auto d-flex align-items-center flex-column gap-4'>
                    <img src={icons} alt="#/" />
                    <p>
                        Connect your Twitch and YouTube accounts with PolarAce to see your account analytics data.
                    </p>
                    <Button
                        onClick={combinedClickHandler}
                        className='btn btn-primary'
                        endIcon={<CallMadeIcon />}
                        sx={{
                            backgroundColor: '#60A9ED',
                            color: '#fff',
                        }}
                    >
                        Connect
                    </Button>
                </div>

            </div>

            <div className={nextView ? 'd-block' : 'd-none'}>

                <div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs className='link-tabs' value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab className='link-tab' iconPosition="start" icon={<img src={iconTwitch} alt="#/" />} label="Twitch" {...a11yProps(0)}
                                sx={{
                                    background: value === 0 ? 'linear-gradient(to top, rgba(239, 255, 65, .1) 25%, rgba(18, 18, 36, 1) 75%)' : 'none',
                                    color: value === 1 ? '#FFFFFF' : 'inherit',
                                }}
                            />
                            <Tab className='link-tab' iconPosition="start" icon={<img src={iconYoutube} alt="#/" />} label="Youtube" {...a11yProps(1)}
                                sx={{
                                    background: value === 1 ? 'linear-gradient(to top, rgba(239, 255, 65, .1) 25%, rgba(18, 18, 36, 1) 75%)' : 'none',
                                    color: value === 1 ? '#FFFFFF' : 'inherit', // Asegúrate de que el texto sea visible
                                }}
                            />
                        </Tabs>
                    </Box>


                    <CustomTabPanel value={value} index={0}>
                        <div className='row card-col p-0'>
                            <div className='col-md-6 col-lg-6 col-12 mb-3 mb-md-0'>
                                <CardAnalytics title={"Followers"} image={AnalyticsImg1} height='100%' />
                            </div>
                            <div className='col-md-6 cooll-lg-5 col-12'>
                                <div className='mb-3'>
                                    <CardAnalytics title={"Viewers"} image={AnalyticsImg3} />
                                </div>
                                <div>
                                    <CardAnalytics title={"Donations"} image={AnalyticsImg2} />
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>
                        <div className='row card-col p-0'>
                            <div className='col-md-6 col-lg-6 col-12 mb-3' >
                                <CardAnalytics title={"Subscribers"} image={AnalyticsImg1} />
                            </div>
                            <div className='col-md-6 col-lg-6 col-12 mb-3'>
                                <CardAnalytics title={"Donations"} image={Card} />
                            </div>
                        </div>
                    </CustomTabPanel>
                </div>
            </div>
        </section>

    );
}

export default Analytics;