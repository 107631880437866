import React, { useState } from 'react';

import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';

import IconUser from '../../assets/img/settings/user.png';
import IconNotification from '../../assets/img/settings/Notification.png';
import IconPassword from '../../assets/img/settings/password.png';

import '../../assets/css/settings/index.css';

import ModalDeleteAccount from '../../components/Modals/ModalDeleteAccount';

const SettingsPage = () => {

    const [openDeleteAc, setOpenDeleteAc] = useState(false);
    const handleOpenDeleteAc = () => setOpenDeleteAc(true);
    const handleCloseDeleteAc = () => setOpenDeleteAc(false);

    const onSubmitDeleteAc = (data) => {
    console.log(data);
    if (data.error) {
    }
    if (!data.error) {
        window.location.href = "/";
    }
    setOpenDeleteAc(false);
    }

    const [changePassword, setChangePassword] = useState(false);

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 55,
        height: 28,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          borderRadius: '5px',
          transitionDuration: '300ms',
          border: '1px solid rgba(135, 150, 176, 0.5)',
          '&.Mui-checked': {
            transform: 'translateX(100%)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#D7E926',
              opacity: 1,
              border: 0,
              ...theme.applyStyles('dark', {
                backgroundColor: '#2ECA45',
              }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#D7E926',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
              color: theme.palette.grey[600],
            }),
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
              opacity: 0.3,
            }),
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
          borderRadius: '5px',
        },
        '& .MuiSwitch-track': {
          borderRadius: "7px",
          backgroundColor: '#2E334A',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
          ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
          }),
        },
      }));

    return (
        <section>
            <div>
                <h1 className="title-dashboard pb-1">
                    Settings
                </h1>
            </div>
            <div className="col-12">
                <div className="d-flex justify-content-between gap-2 flex-md-row settings-bg mb-3">
                    <div className="d-flex flex-column flex-md-row gap-3 align-items-start">

                        <img src={IconNotification} alt="" />
                        <div>
                            <h3 className="m-0">Notifications</h3>
                            <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>

                    <div className="icon-polar-setting">
                        <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                        />
                    </div>
                </div>
                <div className="w-100 settings-bg-item mb-3">
                    <div className='w-100'>
                        <div className="d-flex flex-column flex-md-row gap-3 align-items-start">
                            <img src={IconPassword} alt="#/" />
                            <div className='w-100'>
                                <div className='d-flex flex-lg-row gap-3 flex-column align-items-start'>
                                    <div className='w-100'>
                                        <h3 className="m-0">Change Password </h3>
                                        <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                    <div style={{
                                        minWidth: "200px"
                                    }} className='d-flex justify-content-end gap-2'>
                                        {changePassword ? (
                                            <>
                                                <button onClick={() => setChangePassword(false)} type="button" className="btn btn-dash-secondary">
                                                    Discard
                                                </button>
                                                <button onClick={() => setChangePassword(false)} type="button" className="btn btn-dash-primary">
                                                    Change
                                                </button>
                                            </>
                                        ):(
                                            <button onClick={() => setChangePassword(true)} className="polar-setting__item w-100 btn btn-primary py-3 px-4">
                                                Change Password
                                            </button>
                                        )}
                                    </div>
                                </div>
                                {changePassword ? (
                                    <div className='col-12 login-form no-box mt-3'>
                                        <div className='row'>
                                            <div className='col-lg-4 col-12'>
                                                <label htmlFor="old_password" className="form-label">Old Password</label>
                                                <input type="password" className={`form-control`} id="old_password" placeholder="Enter Old Password" />
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <label htmlFor="new_password" className="form-label">New Password</label>
                                                <input type="password" className={`form-control`} id="new_password" placeholder="Enter New Password" />
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <label htmlFor="new_password" className="form-label">Confirm New Password</label>
                                                <input type="password" className={`form-control`} id="new_password" placeholder="Confirm New Password" />
                                            </div>
                                        </div>
                                    </div>
                                ):(<></>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between gap-2 flex-md-row settings-bg-item__2">
                    <div className="d-flex flex-column flex-md-row gap-3 align-items-start">
                        <img src={IconUser} alt="#/" />
                        <div>
                            <h3 className="m-0">Delete Account</h3>
                            <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>

                    <div>
                        <button onClick={handleOpenDeleteAc} className="setting__item-2 btn btn-primary py-3 px-4">
                            Delete
                        </button>
                    </div>
                </div>
            </div>

            <ModalDeleteAccount open={openDeleteAc} handleClose={handleCloseDeleteAc} onSubmit={onSubmitDeleteAc} />
        </section>
    )
}

export default SettingsPage;