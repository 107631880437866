import React, {useState, useEffect} from "react";
import "../assets/css/category-cards/categoryCards.css";

const CategoryCard = ({icon = null, image, direction, title, text}) => {

    const [width, setWidth] = useState(window.innerWidth);
    // const [height, setHeight] = useState(window.innerHeight);

    const handleResize = () => {
        setWidth(window.innerWidth);
        // setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className={`category-card ${direction}`} style={{
                backgroundImage: `linear-gradient( ${(direction === 'horizontal' && width >= 768) ? "to right":"to top" }, rgb(31, 44, 59) 45%, transparent), url(${image})`
            }}>

                <div className="category-card__body d-flex">
                    {icon ?
                        <div className="category-card__icon pe-4">
                            <img src={icon} alt="icon" />
                        </div>
                        :<></>
                    }
                    <div>
                        <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
                        <p dangerouslySetInnerHTML={{ __html: text }}></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryCard;