import React, { useState } from "react";
import { Box } from "@mui/material";
import CallMadeIcon from '@mui/icons-material/CallMade';
import socialPolarIce from "../assets/img/home/social-polar-ice.png";
import logoImg from "../assets/img/home/Logo-resolution.png";

import ModalNewsletter from "../components/Modals/ModalNewsletter";

const Banner = () => {

    const [show, setShow] = useState(false);

    return (
        <Box className="container">
            <div className="row">
                <div className="col-lg-7 col-md-6 col-12">
                    <h1 className="title-main">
                        <img className="logo-into-title me-3" src={logoImg} alt="Logo" />
                        Polar Ace<br className="d-md-inline d-none" /> 
                        <span className="color-apple"> Free</span> Help for <br className="d-md-inline d-none" /> 
                        Content <span className="color-apple">Creators</span>
                    </h1>
                    <p className="paragraph-main mt-4">
                        Polar Ace has helped thousands of esports players. 
                         Now we want to help millions of content creators with their live streams. 
                        <span className="color-apple"> Free Help: </span><br className="d-md-inline d-none" />
                         No exploitive contracts, no Fake Sponsorships, No Nonsense. 
                         Currently Integrated for <span className="color-apple">Twitch</span> and <span className="color-apple me-1">YouTube.</span> 
                        Sign up now to view and use our free products or read about them below.
                    </p>

                    <div className="col-12 mt-4">
                        <a data-bs-toggle="modal" data-bs-target="#bannerModal" onClick={() => setShow(true)} className="btn btn-primary px-4 py-3" href="/#">
                            <span className="d-flex align-items-center">
                                Get Started 
                                <CallMadeIcon className="ms-2" fontSize="small" />
                            </span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12 mt-4 d-md-block d-none">
                    <img src={socialPolarIce} alt="Social" />
                </div>
            </div>


            <ModalNewsletter show={show} />
        </Box>
    );
}

export default Banner;