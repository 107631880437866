import React from 'react';
import '../../assets/css/Analytics/index.css'

const AnalyticsCard = ({image, title, height = "auto"}) => {
    return (
        <div className='card-analytic' style={{
            height: height
        }}>
            <div className='card-analytic__header'>
                <h4>{title}</h4>
            </div>
            <div className='card-analytic__body'>
                <img className='w-100' src={image} alt="analytic" />
            </div>
        </div>
    );
}

export default AnalyticsCard;