import React, { useState } from 'react';

import {
    FormHelperText
} from '@mui/material';

import "../../assets/css/modal-newsletter/index.css"
import LogoEmail from "../../assets/img/LogoEmail.png"

import ErrorIcon from '@mui/icons-material/Error';

const ModalNewsletter = () => {

    const [error, setError] = useState({
        'email': false,
    });
    const [valueEmail, setValueEmail] = useState('');

    const onSubmit = (event) => {
        event?.preventDefault();

        setError({
            'email': false,
        });

        if (valueEmail === '') {
            setError(prevError => ({
                ...prevError,
                email: true
            }));
        }

        if (valueEmail === '') {
            return;
        }

        window.location.href = "/";
    }

    return (
        <div>
            <div
                className={`modal fade modal-style`} id="bannerModal" tabIndex="-1" aria-labelledby="bannerModalLabel" aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-style-item">
                        <div className="modal-header">
                            <img src={LogoEmail} alt="" />
                        </div>
                        <div className="modal-body w-100 p-5 py-3">
                            <h3 className="fs-3 d-flex justify-content-center align-items-center">Subscribe <span className="color-apple ps-2">Newsletter</span></h3>
                            <p className='d-flex justify-content-center align-items-center'>Subscribe our Newsletter and Get Updates.</p>

                            <div className="mb-3">
                                <input onChange={(event) => setValueEmail(event?.target.value)} className={`form-control ${error.email ? 'error' : 'primary'}`} id="email" placeholder="Enter your email" />
                                {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>}
                            </div>
                        </div>

                        <div className="modal-footer pb-5">
                            <button  onClick={onSubmit} type="button" className="btn btn-primary px-4 py-3">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalNewsletter;