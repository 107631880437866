import React, { useState } from "react";

import {
    Box,
    Tab,
    Tabs,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    // Paper,
    Chip
} from '@mui/material';

import "../../assets/css/sponsorship/index.css";
import "../../assets/css/earnings/index.css";

import Wallet from "../../assets/img/earnings/Wallet.png";
import Graph from "../../assets/img/earnings/Grafica.png";
import Graph2 from "../../assets/img/earnings/Grafica-2.png";

import ModalPayout from "../../components/Modals/ModalPayout";
import ModalPayoutError from "../../components/Modals/ModalPayoutError";
import ModalPayoutSuccess from "../../components/Modals/ModalPayoutSuccess";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
            {value === index && <Box sx={{ pt: 3, pb: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Tables.

function createData(
    Amount,
    Date,
    Method,
    Status,
    ReferenceNumber,
) {
    return { Amount, Date, Method, Status, ReferenceNumber };
}

const rows = [
    createData('$586', "July 12, 2024", "PayPal", "Processing", "#25632214523653"),
    createData('$586', "July 12, 2024", "PayPal", "Paid", "#25632214523653"),
];

const EarningsPage = () => {
    const [value, setValue] = useState(0);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const [openError, setOpenError] = useState(false);
    const handleOpenError = () => setOpenError(true);
    const handleCloseError = () => setOpenError(false);

    const [openSuccess, setOpenSuccess] = useState(false);
    const handleOpenSuccess = () => setOpenSuccess(true);
    const handleCloseSuccess = () => setOpenSuccess(false);

    const handleModalData = (data) => {
        console.log(data);
        if (data.error) {
            handleOpenError();
        }
        if (!data.error) {
            handleOpenSuccess();
        }
        setOpen(false);
    };

    return(
        <section className="section-earnings">
            <div className="d-flex justify-content-between">
                <div>
                    <h1 className="title-dashboard">Earning</h1>
                </div>
                
                <Box>
                    <Tabs 
                        className="tabs-sponsorship border-0" 
                        value={value} 
                        onChange={handleChange} 
                        aria-label="basic tabs example"
                        variant="scrollable"
                    >
                        <Tab className="tab-sponsorship" label="All Over" {...a11yProps(0)} />
                        <Tab className="tab-sponsorship" label="This Year" {...a11yProps(1)} />
                        <Tab className="tab-sponsorship" label="This Month" {...a11yProps(2)} />
                    </Tabs>
                </Box>
            </div>

            <div className="w-100">
                <CustomTabPanel value={value} index={0}>
                    <div className="col-12 p-3 bg-dash-primary rounded-2 card-earning mb-3">
                        <div className="row">
                            <div className="col-xl-6 col-12 px-3 mb-3 mb-xl-0 d-flex align-items-center">
                                <div className="pe-3">
                                    <img src={Wallet} alt="Wallet" />
                                </div>
                                <div>
                                    <p>Total Earning</p>
                                    <h3>$2,58,789.59</h3>
                                </div>
                            </div>
                            <div className="col-xl-6 col-12 px-3">
                                <div className="w-100 d-flex bg-gray-primary justify-content-between align-items-center px-3 py-2 rounded-3">
                                    <div>
                                        <p>Earning Balance</p>
                                        <h4>$1,535.59</h4>
                                    </div>
                                    <div>
                                        <button onClick={handleOpen} className="btn btn-dash-primary">
                                            Withdraw
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6 col-12 mb-3">
                                <div className="card-earning-secondary">
                                    <div className="card-earning-secondary__header">
                                        <h4>Earning Through OBS Extension</h4>
                                        <p className="price">$1,00,535.59</p>
                                    </div>
                                    <div className="card-earning-secondary__body">
                                        <img className="w-100" src={Graph} alt="Earnings" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="card-earning-secondary">
                                    <div className="card-earning-secondary__header">
                                        <h4>Earning Through Sponserships</h4>
                                        <p className="price">$1,53,265.59</p>
                                    </div>
                                    <div className="card-earning-secondary__body">
                                        <img className="w-100" src={Graph2} alt="Earnings" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 mb-3">
                        <h1 className="title-dashboard">Payout History</h1>

                        <TableContainer className="table-dash-polar">
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow key={"1"}>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Method</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Reference Number</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={Math.random()}
                                        >
                                        <TableCell>
                                            {row.Amount}
                                        </TableCell>
                                        <TableCell>{row.Date}</TableCell>
                                        <TableCell>{row.Method}</TableCell>
                                        <TableCell>
                                            <Chip className="rounded-1 chip-polar" label={row.Status} color={row.Status === "Paid" ? "success":"warning"} />
                                        </TableCell>
                                        <TableCell>{row.ReferenceNumber}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <div className="col-12 p-3 bg-dash-primary rounded-2 card-earning mb-3">
                        <div className="row">
                            <div className="col-xl-6 col-12 px-3 mb-3 mb-xl-0 d-flex align-items-center">
                                <div className="pe-3">
                                    <img src={Wallet} alt="Wallet" />
                                </div>
                                <div>
                                    <p>Total Earning</p>
                                    <h3>$70,819.59</h3>
                                </div>
                            </div>
                            <div className="col-xl-6 col-12 px-3">
                                <div className="w-100 d-flex bg-gray-primary justify-content-between align-items-center px-3 py-2 rounded-3">
                                    <div>
                                        <p>Earning Balance</p>
                                        <h4>$70,819.59</h4>
                                    </div>
                                    <div>
                                        <a className="btn btn-dash-primary" href="/#">
                                            Withdraw
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6 col-12 mb-3">
                                <div className="card-earning-secondary">
                                    <div className="card-earning-secondary__header">
                                        <h4>Earning Through OBS Extension</h4>
                                        <p className="price">$1,00,535.59</p>
                                    </div>
                                    <div className="card-earning-secondary__body">
                                        <img className="w-100" src={Graph} alt="Earnings" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="card-earning-secondary">
                                    <div className="card-earning-secondary__header">
                                        <h4>Earning Through Sponserships</h4>
                                        <p className="price">$1,53,265.59</p>
                                    </div>
                                    <div className="card-earning-secondary__body">
                                        <img className="w-100" src={Graph2} alt="Earnings" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 mb-3">
                        <h1 className="title-dashboard">Payout History</h1>

                        <TableContainer className="table-dash-polar">
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Method</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Reference Number</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                        key={row.name}
                                        >
                                        <TableCell>
                                            {row.Amount}
                                        </TableCell>
                                        <TableCell>{row.Date}</TableCell>
                                        <TableCell>{row.Method}</TableCell>
                                        <TableCell>
                                            <Chip className="rounded-1 chip-polar" label={row.Status} color={row.Status === "Paid" ? "success":"warning"} />
                                        </TableCell>
                                        <TableCell>{row.ReferenceNumber}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <div className="col-12 p-3 bg-dash-primary rounded-2 card-earning mb-3">
                        <div className="row">
                            <div className="col-xl-6 col-12 px-3 mb-3 mb-xl-0 d-flex align-items-center">
                                <div className="pe-3">
                                    <img src={Wallet} alt="Wallet" />
                                </div>
                                <div>
                                    <p>Total Earning</p>
                                    <h3>$70,819.59</h3>
                                </div>
                            </div>
                            <div className="col-xl-6 col-12 px-3">
                                <div className="w-100 d-flex bg-gray-primary justify-content-between align-items-center px-3 py-2 rounded-3">
                                    <div>
                                        <p>Earning Balance</p>
                                        <h4>$70,819.59</h4>
                                    </div>
                                    <div>
                                        <a className="btn btn-dash-primary" href="/#">
                                            Withdraw
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6 col-12 mb-3">
                                <div className="card-earning-secondary">
                                    <div className="card-earning-secondary__header">
                                        <h4>Earning Through OBS Extension</h4>
                                        <p className="price">$1,00,535.59</p>
                                    </div>
                                    <div className="card-earning-secondary__body">
                                        <img className="w-100" src={Graph} alt="Earnings" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="card-earning-secondary">
                                    <div className="card-earning-secondary__header">
                                        <h4>Earning Through Sponserships</h4>
                                        <p className="price">$1,53,265.59</p>
                                    </div>
                                    <div className="card-earning-secondary__body">
                                        <img className="w-100" src={Graph2} alt="Earnings" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 mb-3">
                        <h1 className="title-dashboard">Payout History</h1>

                        <TableContainer className="table-dash-polar">
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Method</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Reference Number</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                        key={row.name}
                                        >
                                        <TableCell>
                                            {row.Amount}
                                        </TableCell>
                                        <TableCell>{row.Date}</TableCell>
                                        <TableCell>{row.Method}</TableCell>
                                        <TableCell>
                                            <Chip className="rounded-1 chip-polar" label={row.Status} color={row.Status === "Paid" ? "success":"warning"} />
                                        </TableCell>
                                        <TableCell>{row.ReferenceNumber}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </CustomTabPanel>
            </div>

            <ModalPayout handleClose={handleClose} open={open} onSubmit={handleModalData} />
            <ModalPayoutError handleClose={handleCloseError} open={openError} />
            <ModalPayoutSuccess handleClose={handleCloseSuccess} open={openSuccess} />
        </section>
    );
}

export default EarningsPage;