import React from "react";
import ArticleIcon from '@mui/icons-material/Article';
import "../../assets/css/card-sponsor/index.css";

const CardSponsor = ({image, title, text}) => {
    return (
        <div className="card-sponsor">
            <div className="card-sponsor__header">
                <img src={image} alt="" />
            </div>
            <div className="card-sponsor__body">
                <h4>
                    {title}
                </h4>
                <p>
                    {text}
                </p>
            </div>
            <div className="card-sponsor__footer">
                <div className="d-flex w-100">
                    <div className="d-flex gap-2">
                        <button type="button" className="btn btn-dash-primary">
                            Accept
                        </button>
                        <button type="button" className="btn btn-dash-secondary">
                            Decline
                        </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-end w-100">
                        <a className="link-info" href="/#">
                            <ArticleIcon />
                            Request More Information
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardSponsor;