import React from 'react';
import {
    Box,
    Modal,
} from '@mui/material';

import LogoutIcon from "../../assets/img/logout/LogoutIcon.png";

import "../../assets/css/payout/index.css";

export default function ModalLogOut({open, handleClose, onSubmit}) {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-polar" style={{
                maxWidth: "420px"
            }}>
                <div className='modal-polar__header text-center'>
                    <div className='icon-polar'>
                        <img src={LogoutIcon} alt="walletIcon" />
                    </div>
                    <h3 className='my-3'>Are you sure you want to log out?</h3>
                    <p className='fs-14'>Please confirm if you want to log out.</p>
                </div>
                <div className='modal-polar__body'>
                    <div className='w-100 d-flex align-items-center justify-content-center gap-2'>
                        <button onClick={() => onSubmit({error: false, message: "Ok!"})} type="button" className="btn btn-dash-primary">
                            Yes, Log Out
                        </button>
                        <button onClick={() => onSubmit({error: true, message: "Ha ocurrido un error"})} type="button" className="btn btn-dash-secondary">
                            No, Cancel
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
