import React, { useEffect, useState } from "react";

import Logo from "../../assets/img/logo.png";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import CircleIcon from '@mui/icons-material/Circle';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import ChatIcon from '@mui/icons-material/Chat';
import TollIcon from '@mui/icons-material/Toll';
import SupportIcon from '@mui/icons-material/Support';
import SensorsIcon from '@mui/icons-material/Sensors';
import CloseIcon from '@mui/icons-material/Close';

import "../../assets/css/sidebar-dashboard/index.css";

const SidebarDashboard = ({onClose}) => {
    const [path, setPath] = useState("");

    const getPath = () => {
        const currentPath = window.location.pathname;
        setPath(currentPath);

        return currentPath;
    }

    useEffect(() => {
        getPath();
    });

    return (
        <div className="sidebar-dashboard">

            <div onClick={onClose} className="close-toggle-menu">
                <CloseIcon />                
            </div>

            <div className="sidebar-logo">
                <img src={Logo} alt="Logo" />
            </div>

            <div className="sidebar-dashboard__body col-12">
                <nav className="navbar">
                    <ul>
                        <li>
                            <a className={path.includes('/dashboard') || path.includes('/analytics') ? "active":""} href="/analytics">
                                <InsertChartIcon />
                                Analytics
                            </a>
                        </li>
                        <li>
                            <a className={path.includes('/obs-extension') ? "active":""} href="/obs-extension">
                                <CircleIcon />
                                OBS Extension
                            </a>
                        </li>
                        <li>
                            <a className={path.includes('/sponsorship-offers') ? "active":""} href="/sponsorship-offers">
                                <WaterDropIcon />
                                Sponsorship Offers
                            </a>
                        </li>
                        <li>
                            <a className={path.includes('/archive-chat') ? "active":""} href="/archive-chat">
                                <ChatIcon />
                                Archive Chat
                            </a>
                        </li>
                        <li>
                            <a className={path.includes('/earnings') ? "active":""} href="/earnings">
                                <TollIcon />
                                Earnings
                            </a>
                        </li>
                        <li>
                            <a className={path.includes('/help-and-support') ? "active":""} href="/help-and-support">
                                <SupportIcon />
                                Help and Support
                            </a>
                        </li>
                        <li>
                            <a className={path.includes('/connect-accounts') ? "active":""} href="/connect-accounts">
                                <SensorsIcon />
                                Connect Accounts
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default SidebarDashboard;