import React, { useState } from 'react';

import LogoLateral from '../../assets/img/sign-up/Logo-lateral.png';

import "../../assets/css/sign-up/index.css";
import 'animate.css';

const SignUpComplete = () => {
    
    const [steps, setSteps] = useState(1);
    const [showStep1, setShowStep1] = useState(true);
    const [showStep2, setShowStep2] = useState(false);

    const stepMove = (step) => {
        if (step === 2) {
            setSteps(2);
            setTimeout(() => {
                setShowStep1(false);
                setShowStep2(true);
            }, 1000);
        }
        if (step === 1) {
            setSteps(1);
            setTimeout(() => {
                setShowStep1(true);
                setShowStep2(false);
            }, 1000);
        }
        if (step === 3) {
            window.location.href = "/dashboard";
        }
    }

    const countries = [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo, Democratic Republic of the",
        "Congo, Republic of the",
        "Costa Rica",
        "Côte d'Ivoire",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, North",
        "Korea, South",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
    ];

    return (
        <div className='body singlePages'>
            <div className='col-12 px-4'>
                <div className='row'>
                    <div className='col-lg-4 col-md-5 d-md-block d-none min-vh-100 p-4'>
                        <div className='lateral-register'>
                            <img src={LogoLateral} alt="Lateral" />
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-7 col-12 p-4'>
                        <form className='login-form no-box register-complete-form overflow-hidden h-100'>
                            <div className='my-2 w-100 d-flex flex-column-reverse flex-md-row justify-content-between align-items-center'>
                                <h2>Please Enter the Details to Complete<br />Sign Up Process</h2>
                                <div className="progress col-md-3 col-12 my-3" style={{ height: "10px" }}>
                                    <div className="progress-bar bg-warning" role="progressbar" style={{ "width": steps !== 2 ? "50%":"100%" }} ariaValuenow="75" ariaValuemin="0" ariaValuemax="100"></div>
                                </div>
                            </div>
                            <div className={`col-12 ${steps !== 1 ? 'animate__animated animate__fadeOutLeft' : 'animate__animated animate__fadeInLeft'} ${!showStep1 ? 'd-none' : ''} `}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" className={`form-control`} id="name" placeholder="Your Name" />
                                    {/* {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>} */}
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-3">
                                            <label htmlFor="birth" className="form-label">Date of Birth</label>
                                            <input type="date" className={`form-control`} id="birth" placeholder="00/00/0000" />
                                            {/* {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>} */}
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-3">
                                            <label htmlFor="gender" className="form-label">Gender</label>
                                            <select className={`form-control`} name="gender" id="gender">
                                                <option value="">Select</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                            {/* {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>} */}
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-3">
                                            <label htmlFor="country" className="form-label">Country</label>
                                            <select className={`form-control`} name="country" id="country">
                                                <option value="">Country</option>
                                                {countries.map((value, key) => (
                                                    <option key={key} value={value}>{value}</option>
                                                ))}
                                            </select>
                                            {/* {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>} */}
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-12'>
                                                <div className="mb-3">
                                                    <label htmlFor="city" className="form-label">City</label>
                                                    <input type="text" className={`form-control`} id="city" placeholder="City" />
                                                    {/* {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>} */}
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-12'>
                                                <div className="mb-3">
                                                    <label htmlFor="zipcode" className="form-label">Zipcode</label>
                                                    <input type="text" className={`form-control`} id="zipcode" placeholder="Zipcode" />
                                                    {/* {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <input type="text" className={`form-control`} id="address" placeholder="Your Address" />
                                    {/* {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>} */}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="BroadcastingLanguage" className="form-label">Broadcasting Language</label>
                                    <select className={`form-control`} name="BroadcastingLanguage" id="BroadcastingLanguage">
                                        <option value="">Select</option>
                                        <option value="United States">English</option>
                                        <option value="Mexico">Spanish</option>
                                    </select>
                                    {/* {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>} */}
                                </div>
                            </div>

                            <div className={`col-12 ${steps !== 2 ? 'animate__animated animate__fadeOutRight' : 'animate__animated animate__fadeInRight'} ${!showStep2 ? 'd-none' : ''} `}>
                                <div className="mb-3">
                                    <label htmlFor="content" className="form-label">What type of content do you make?</label>
                                    <textarea className={`form-control`} id="content" rows={4} placeholder='Your type content'></textarea>
                                    {/* {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>} */}
                                </div>
                            </div>

                            <div className='d-flex align-items-center justify-content-between mt-4 bottom-register w-100'>
                                <button onClick={() => stepMove(1)} type='button' className='btn btn-info px-5 py-3'>
                                    Back
                                </button>

                                <button onClick={() => stepMove(steps === 1 ? 2:3)} type='button' className='btn btn-primary px-5 py-3'>
                                    {steps === 2 ? 
                                        ('Finish'):('Next')
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUpComplete;