import React, { useState } from "react"

import '../../assets/css/SetPayoutMethod/index.css'

import iconback from '../../assets/img/set-payout-method/icon-bank.png';
import iconpaypal from '../../assets/img/set-payout-method/icon-PayPal.png';
import bankConnect from '../../assets/img/set-payout-method/icon-bank-active.png';
import LinkIcon from '@mui/icons-material/Link';

import ModalSetPayout from "../../components/Modals/ModalSetPayout";

import { Chip } from "@mui/material";

const SetPayoutMethodPage = () => {
    const [connect, setConnect] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleModalData = (data) => {
        console.log(data);
        setConnect(true);
        setOpen(false);
    };

    return (
        <section>
            <div>
                <h1 className="title-dashboard pb-1">
                    Set Payout Method
                </h1>
                <p className="mb-4 fs-12">
                    Set your Payout payment method.
                </p>
            </div>
            <div className="col-12">
                <div className="d-flex justify-content-between flex-md-row flex-column SetPayoutMethod-bg mb-3">
                    <div className="d-flex gap-3 align-items-center">

                        <img src={iconback} alt="" />
                        <div>
                            <h3 className="m-0">Bank Account</h3>
                            <p className="m-0">Connect your bank account.</p>
                            {connect ? (
                                <Chip 
                                    className="rounded-2 chip-polar px-2 py-2 h-auto mt-3"
                                    label="Bank of America - XX3589"
                                    icon={<img src={bankConnect} />}    
                                    style={{
                                        background: "#2A3B50",
                                        color: "#BFBFD2"
                                    }} 
                                />
                            ):(
                                <></>
                            )}
                        </div>
                        
                    </div>
                    
                    {connect ? (
                        <button onClick={() => setConnect(false)} type="button" className="btn btn-dash-secondary py-3">
                            Disconnect
                        </button>
                    ):(
                        <button onClick={handleOpen} className="btn btn-primary px-4 py-3">
                            <LinkIcon className="me-2" />
                            Connect
                        </button>
                    )}
                </div>
                <div className="d-flex justify-content-between flex-md-row flex-column SetPayoutMethod-bg">
                    <div className="d-flex gap-3 align-items-center">
                        <img src={iconpaypal} alt="#/" />
                        <div>
                            <h3 className="m-0">PayPal</h3>
                            <p className="m-0">Connect your PayPal account.</p>
                        </div>
                    </div>

                    <button className="btn btn-primary px-4 py-3">
                        <LinkIcon className="me-2"/>
                        Connect
                    </button>
                </div>
            </div>

            <ModalSetPayout open={open} handleClose={handleClose} onSubmit={handleModalData} />
        </section>
    )
}

export default SetPayoutMethodPage