import "../../assets/css/foms/index.css"

const ContactForm = ({ action, method }) => {
    return (
        <form className="contact-form" method={method} action={action}>
            <h3>
                Send Us a Message or <br /> Email <span className="color-apple">support@polarace.com</span>
            </h3>
            <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">Name</label>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Your Name" />
            </div>
            
            <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">Email</label>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Your Email" />
            </div>
            <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">Subject</label>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Subject"/>
            </div>
            <div className="mb-3">
                <label  for="exampleFormControlTextarea1" className="form-label">Message</label>
                <textarea className="form-control" placeholder="Message type here" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
            <div>
                <button className="btn btn-primary px-4 py-3 w-100">
                    Send Message
                </button>
            </div>
        </form>
    );
}

export default ContactForm;