import React, { useEffect, useState } from 'react';
import "../assets/css/website.css";
import logoimg from "../assets/img/logo.png";
import Facebooklogo from "../assets/img/Facebook.png";
import instagramlogo from "../assets/img/instagram.png";
import xIconx8logo from "../assets/img/xIconx8.png";
import Youtubelogo from "../assets/img/youtube_icon.png";
import DiscordIcon from "../assets/img/discord_icon.png";
import Twitchlogo from "../assets/img/twitch.png";
import closeImg from "../assets/img/closenav.png"


const MainLayout = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const controlMenu = () => {
    if (openMenu) {
      document.getElementById("menu").classList.remove('animate__animated');
      document.getElementById("menu").classList.remove('animate__fadeInDown');
      document.getElementById("menu").classList.add('animate__animated');
      document.getElementById("menu").classList.add('animate__fadeOutUp');
      
      setTimeout(() => {
        setOpenMenu(false);
        document.getElementById("menu").classList.remove('animate__animated');
        document.getElementById("menu").classList.remove('animate__fadeOutUp');
      }, 500);
    }else{
      setOpenMenu(true);
    }
  }

  useEffect(() => {
    document.body.classList.add('body');
    document.body.classList.add('homePage');
  }, []);
  
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      if (windowHeight + scrollY > 900) {
        document.getElementById("header").classList.add('bg-dash-primary');
      } else {
        document.getElementById("header").classList.remove('bg-dash-primary');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Remover el event listener en la limpieza del hook
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  // const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
      setWidth(window.innerWidth);
      // setHeight(window.innerHeight);
  };

  useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <div>
      <header id='header'>
        <div className="container">
          <nav className="navbar navbar-expand-lg container nav-menu justify-content-between">

            <div className='d-flex align-items-center'>
              <div className="logo">
                <img src={logoimg} alt="#" />
                <p className='mb-0'>Polar Ace</p>
              </div>
            </div>
            <div className={`navbar-toggler`} type="button"
              onClick={controlMenu}
              aria-expanded={openMenu ? "true":"false"}
            >
              <i className="bi bi-list"></i>
              <img src={closeImg} alt="" />
            </div>


            <div className={`collapse navbar-collapse navbar-header ${width <= 992 && openMenu ? "animate__animated animate__fadeInDown show":""}`} id="menu">
              <ul className="navbar-nav navbar-nav-header me-auto mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link" href="/" role="button">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#" role="button">Advertisers</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact-us" role="button">Contact Us</a>
                </li>
                <li className="nav-item">
                  <a className="btn button-color__sign" href="/register" role="button">Sign up</a>
                </li>
                <li className="nav-item">
                  <a className="btn button-color__login" href="/login">Login</a>
                </li>
              </ul>

              <div className='nav-footer__header'>
                <a className="btn button-color__sign" href="/register" role="button">Sign up</a>
                <a className="btn button-color__login" href="/login">Login</a>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <main className='mt-5'>{children}</main>

      <footer className="footer">
        <div className='container border-bottom my-4 pb-4 border-black'>
          <div className='d-md-flex justify-content-md-between'>

            <div className='d-md-flex align-items-center'>
              <div className='logo-footer pe-4'>
                <img srcSet={logoimg} alt="#" />
              </div>

              <ul className='nav-contact-footer list-unstyled d-flex align-items-center mb-0 pt-md-0 pt-4'>
                <li>
                  <a className='text-color__home' href="/">
                    Home
                  </a>
                </li>
                <li>
                  <a className='text-color__advertisers' href="/#">
                    Advertisers
                  </a>
                </li>
                <li>
                  <a className='text-color__Contact' href="/contact-us">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            <div className='d-flex gap-5 d-flex align-items-center pt-md-0 pt-4'>
              <div className='icons-footer'>
                <a href="https://facebook.com/PolarAceGG/">
                  <img srcSet={Facebooklogo} alt="#" />
                </a>
              </div>
              <div className='icons-footer'>
                <a href="https://www.instagram.com/polaracegg">
                  <img srcSet={instagramlogo} alt="#" />
                </a>
              </div>
              <div className='icons-footer'>
                <a href="https://twitter.com/polaracegg">
                  <img srcSet={xIconx8logo} alt="#" />
                </a>
              </div>
              <div className='icons-footer'>
                <a href="https://youtube.com/@polarace?si=rzxiPseyW2geCNWS">
                  <img srcSet={Youtubelogo} alt="#" />
                </a>
              </div>
              <div className='icons-footer'>
                <a href="https://twitch.tv/polaracetv">
                  <img srcSet={Twitchlogo} alt="#" />
                </a>
              </div>
              <div className='icons-footer'>
                <a href="https://discord.com/invite/polarace">
                  <img srcSet={DiscordIcon} alt="#" />
                </a>
              </div>
            </div>

          </div>
        </div>


        <div className='container term-privacy'>
          <div className='Copyright'>
            <p>Copyright 2024 all rights reserved.</p>
          </div>
          <div className='d-flex gap-5 '>
            <div>
              <a className='services' href="/#">
                Terms of Services
              </a>
            </div>
            <div>
              <a className='privacy-policy' href="/#">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
};

export default MainLayout;
