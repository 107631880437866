import React, { useState } from 'react';

import { 
    FormHelperText
} from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import "../../assets/css/forgot-password/index.css";
import logoimg from "../../assets/img/home/Logo-resolution.png";
        
const ForgotPassword = () => {
    const [error, setError] = useState({
            'password': false
        });
        const [valueEmail, setValueEmail] = useState('');
        
        const onSubmit = (event) => {
            event?.preventDefault();
    
            setError({
                'email': false,
            }); 
            
            if (valueEmail == '') {
                setError(prevError => ({
                    ...prevError,
                    email: true
                }));
            }
    
            if (valueEmail == '') {
                return;
            }
    
            window.location.href = "/";
        }

    return (
        <div className='body singlePages'>
            <form className="col-lg-6 col-xl-4 col-md-6 col-10 mx-auto form-forgot__password" onSubmit={onSubmit}>
                <div className="">
                    <div className='form-password__item'>
                        <img src={logoimg} alt="" />
                        <h1>
                            Forgot your password?
                        </h1>
                        <p>
                            Please enter your email address and we'll send you a access code to reset your password.
                        </p>
                    </div>
                <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                        <input onChange={(event) => setValueEmail(event?.target.value)} type="email" className={`form-control ${error.email ? 'error':'primary'}`}  id="exampleFormControlInput1" placeholder="Enter your email"/>
                        {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>}
                    </div>
                    <div className="mb-3 d-flex justify-content-center">
                        <button className="btn btn-primary">
                            Send reset link 
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ForgotPassword;