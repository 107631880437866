import ContactForm from "../../components/Forms/ContactForm";
import "../../assets/css/contact/index.css";

const ContactUs = () => {
    return (
        <section className="col-12 section-contactUs py-5 min-height-100">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12 bg-envelope-contact">
                        <h5 className="color-apple breadcrumb">Contact Us</h5>
                        <h1 className="title-main">
                            We've <br className="d-md-block d-none"/> been waiting <br className="d-md-block d-none"/> for <span className="color-apple">you</span>.
                        </h1>
                        <p className="paragraph-main">we want to hear from you. Let us know <br className="d-md-block d-none"/> how we can help?</p>
                    </div>
                    <div className="col-lg-5 offset-lg-1 col-md-6 col-12">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;