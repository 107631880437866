import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Layouts
import MainLayout from './layouts/MainLayout';
import DashboardLayout from './layouts/DashboardLayout';

// Website Pages
import HomePage from './pages/HomePage';
import ContactUs from './pages/Contact/ContactUs';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Login from './pages/Login/Login';
import SignUp from './pages/SignUp/SignUp';
import SignUpComplete from './pages/SignUp/SignUpComplete';

// Dashboard Pages
import ObsExtension from './pages/OBSExtension/ObsExtension';
import HelpAndSupport from './pages/HelpAndSupport/HelpAndSupport';
import SponsorshipPage from './pages/SponsorshipPage/SponsorshipPage';
import Analytics from './pages/Analytics/Analytics';
import EarningsPage from './pages/Earnings/EarningsPage';
import ArchiveChat from './pages/ArchiveChat/Archive';
import SettingsPage from './pages/Settings/SettingsPage';
import SetPayoutMethodPage from  './pages/SetPayoutMethod/SetPayoutMethodPage';
import MyProfilePage from './pages/MyProfile/MyProfilePage';
import ConnectAccountsPage from './pages/ConnectAccounts/ConnectAccountsPage';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<MainLayout><HomePage /></MainLayout>} />
      <Route path="/contact-us" element={<MainLayout><ContactUs /></MainLayout>} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/complete-register" element={<SignUpComplete />} />
      
      {/* Dashboard Pages */}
      <Route path="/dashboard" element={<DashboardLayout><Analytics /></DashboardLayout>} />
      <Route path="/obs-extension" element={ <DashboardLayout><ObsExtension /></DashboardLayout>} />
      <Route path="/help-and-support" element={ <DashboardLayout><HelpAndSupport /></DashboardLayout>} />
      <Route path="/sponsorship-offers" element={ <DashboardLayout><SponsorshipPage /></DashboardLayout>} />
      <Route path="/analytics" element={<DashboardLayout><Analytics /></DashboardLayout>} />
      <Route path="/earnings" element={ <DashboardLayout><EarningsPage /></DashboardLayout>} />
      <Route path="/archive-chat" element={ <DashboardLayout><ArchiveChat /></DashboardLayout>} />
      <Route path="/settings" element={ <DashboardLayout><SettingsPage /></DashboardLayout>} />
      <Route path="/set-payout-method" element={ <DashboardLayout><SetPayoutMethodPage /></DashboardLayout>} />
      <Route path="/my-profile" element={ <DashboardLayout><MyProfilePage /></DashboardLayout>} />
      <Route path="/connect-accounts" element={ <DashboardLayout><ConnectAccountsPage /></DashboardLayout>} />

    </Routes>
  </Router>
);

export default App;
