import React from 'react';
import '../../assets/css/help-and-support/index.css'
import messenger from '../../assets/img/help-and-support/messenger.png';

const HelpAndSupport = () => {
    return (
        <>
            <div className="w-100">

                <h3 className='title-support mt-4 mb-0'>
                    Help and Support
                </h3>
                <div className='section-support mt-0 d-flex flex-column align-items-start'>
                    <div className='col-xl-7 col-12 p-4'>
                        <img className='mb-3' src={messenger} alt="#/" />
                        <p>Send Us a Message or Email support@polarace.com</p>
                        <form className='w-100'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name </label>
                                            <input type="email" className="form-control m-0" id="name" placeholder="Your Name" />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="email" className="form-control m-0" id="email" placeholder="Your Email" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="subject" className="form-label">Subject</label>
                                <input type="email" className="form-control m-0" id="subject" placeholder="Subject" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Messege" className="form-label">Message</label>
                                <textarea className="form-control m-0" id="Messege" placeholder="Message type here" aria-rowindex={3}></textarea>
                            </div>
                            <div>
                                <button className="btn btn-primary px-4 py-3">
                                    Send Message
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HelpAndSupport;