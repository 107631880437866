import React from 'react';
import {
    Box,
    Modal,
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';

import "../../assets/css/notifications/index.css";

export default function ModalNotifications({open, handleClose}) {

    const deleteNotifications = () => {
        const elements = document.querySelectorAll('.card-notification');

        elements.forEach((element, index) => {
            setTimeout(() => {
                element.classList.add("animate__animated");
                element.classList.add("animate__fadeOutRight");
                
                setTimeout(() => {
                    element.classList.add("d-none");
                }, 1500);
            }, index * 100);
        });
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-notifications">
                <div className='modal-notifications__header'>
                    <div className='d-flex align-items-center gap-2'>
                        <NotificationsIcon />
                        <h4>Notifications</h4>
                    </div>

                    <button type='button' onClick={handleClose} className='close-icon bg-transparent'>
                        <CloseIcon />
                    </button>
                </div>

                <div className='modal-notifications__body px-md-4 px-2'>
                    <div className='d-flex w-100 justify-content-between align-items-center py-3'>
                        <h6>
                            <span>(3)</span> New notifications Received
                        </h6>

                        <button onClick={deleteNotifications} className='btn btn-link'>
                            Clear All
                        </button>
                    </div>

                    <div className='cards-notifications mt-3'>
                        <div className='card-notification forRead'>
                            <p className='card-notification__info fs-12'>
                                You have received information for “Sponsorship offer from Coke” Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                            </p>

                            <div className='card-notification__footer mt-2'>
                                <p className='card-notification__time fs-11 mb-0'>Just Now</p>
                            </div>
                        </div>
                        <div className='card-notification forRead'>
                            <p className='card-notification__info fs-12'>
                                You have received information for “Sponsorship offer from Coke” Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                            </p>

                            <div className='card-notification__footer mt-2'>
                                <p className='card-notification__time fs-11 mb-0'>Just Now</p>
                            </div>
                        </div>
                        <div className='card-notification'>
                            <p className='card-notification__info fs-12'>
                                You have received information for “Sponsorship offer from Coke” Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                            </p>

                            <div className='card-notification__footer mt-2'>
                                <p className='card-notification__time fs-11 mb-0'>Just Now</p>
                            </div>
                        </div>
                        <div className='card-notification'>
                            <p className='card-notification__info fs-12'>
                                You have received information for “Sponsorship offer from Coke” Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                            </p>

                            <div className='card-notification__footer mt-2'>
                                <p className='card-notification__time fs-11 mb-0'>Just Now</p>
                            </div>
                        </div>
                        <div className='card-notification forRead'>
                            <p className='card-notification__info fs-12'>
                                You have received information for “Sponsorship offer from Coke” Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                            </p>

                            <div className='card-notification__footer mt-2'>
                                <p className='card-notification__time fs-11 mb-0'>Just Now</p>
                            </div>
                        </div>
                        <div className='card-notification forRead'>
                            <p className='card-notification__info fs-12'>
                                You have received information for “Sponsorship offer from Coke” Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                            </p>

                            <div className='card-notification__footer mt-2'>
                                <p className='card-notification__time fs-11 mb-0'>Just Now</p>
                            </div>
                        </div>

                    </div>
                </div>

            </Box>
        </Modal>
    );
}
