import React, { useState } from 'react';

import Box from '@mui/material/Box';

import "../../assets/css/archivechat/index.css";

import Twitch from '../../assets/img/ArchiveChat/Twitch.png'
import Youtube from '../../assets/img/ArchiveChat/Youtube.png'
import LiveStream from '../../assets/img/ArchiveChat/LiveStream.png'

import {
    styled,
    FormControlLabel,
    Radio,
} from '@mui/material';

import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ArchiveChat = () => {
    const StyledFormControlLabel = styled((props) => (
        <FormControlLabel {...props} style={{
            border: props.checked ? '1.5px solid #D7E926' : '1.5px solid transparent'
        }} />
    ))(({ theme, checked }) => ({
        ...(checked && {
            '.MuiFormControlLabel-label': {
                color: '#fff',
            },
        }),
    }));

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();

        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
        }

        return (
            <StyledFormControlLabel
                checked={checked}
                {...props}
                label={
                    <Box width='100%' display="flex" alignItems="center">
                        <span className="icon-label">
                            <img src={props.icon} alt="Influencer" />
                        </span>
                        {props.label}
                    </Box>
                }
            />
        );
    }

    const [viewStream, setViewStream] = useState(false);

    return (
        <section className='section-ArchiveChat'>
            <h1 className="title-dashboard">Archive Chat</h1>

            <div>
                <div className='section-ArchiveChat__item'>
                    <h4>
                        Please select the platform from which you'd like to archive chat messages.
                    </h4>

                    <div className='d-flex align-items-center '>
                        <RadioGroup className='group-label-polar' name="use-radio-group" defaultValue="first" row={true}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-xl-3 col-lg-6 col-12'>
                                        <MyFormControlLabel name="typeAccount" className='form-control__label fs-label__archive icon-noBg p-3'
                                            value="Twitch" label="Twitch" icon={Twitch} control={<Radio onChange={() => setViewStream(true)} {...a11yProps(0)} />} />
                                    </div>
                                    <div className='col-xl-3 col-lg-6 col-12'>
                                        <MyFormControlLabel name="typeAccount" className='form-control__label fs-label__archive icon-noBg p-3'
                                            value="Youtube" label="Youtube" icon={Youtube} control={<Radio onChange={() => setViewStream(true)} {...a11yProps(1)} />} />
                                    </div>
                                </div>
                            </div>
                        </RadioGroup>
                    </div>

                    <div>
                        <p>Date of the streams you'd like to archive chat messages.  </p>

                        <div>
                            <input className='form-control-polar form-control mb-3' type="date" name="" id="" />
                        </div>
                    </div>

                    <div className={viewStream ? 'd-block' : 'd-none'}>
                        <div>
                            <p>
                                Choose live Stream Videos
                            </p>
                        </div>
                        <div>
                            <RadioGroup className='group-label-polar' name="use-radio-group" defaultValue="first" row={true}>

                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-xl-4 col-lg-6 col-12'>
                                            <MyFormControlLabel name="typeAccount" className='form-control__label fs-label__archive label-radio-archive flex-row-reverse justify-content-between align-items-center'
                                            value="LiveStream-1" label="Live stream video title here" icon={LiveStream} control={<Radio {...a11yProps(0)} />} />
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-12'>
                                            <MyFormControlLabel name="typeAccount" className='form-control__label fs-label__archive label-radio-archive flex-row-reverse justify-content-between align-items-center'  {...a11yProps(1)}
                                            value="LiveStream-2" label="Live stream video title here" icon={LiveStream} control={<Radio {...a11yProps(0)} />} />
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-12'>
                                            <MyFormControlLabel name="typeAccount" className='form-control__label fs-label__archive label-radio-archive flex-row-reverse justify-content-between align-items-center'  {...a11yProps(3)}
                                            value="LiveStream-3" label="Live stream video title here" icon={LiveStream} control={<Radio {...a11yProps(0)} />} />
                                        </div>
                                    </div>
                                </div>
                            </RadioGroup>

                        </div>
                    </div>

                    <div className='mt-5'>
                        <button className='btn btn-primary'>
                            Archive Chat
                        </button>
                    </div>
                </div>
            </div>


        </section >
    );
}

export default ArchiveChat;