import React, { useState } from 'react';

import { 
    FormHelperText
} from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import Logo from '../../assets/img/home/Logo-resolution.png'
import "../../assets/css/website.css";
import "../../assets/css/Login/index.css"
import "../../assets/css/foms/index.css"

const Login = () => {
    const [error, setError] = useState({
        'password': false,
        'email': false,
    });
    const [valueEmail, setValueEmail] = useState('');
    const [valuepassword, setValuepassword] = useState('');
    
    const onSubmit = (event) => {
        event?.preventDefault();

        setError({
            'password': false,
            'email': false,
        }); 
        
        if (valueEmail === '') {
            setError(prevError => ({
                ...prevError,
                email: true
            }));
        }
        
        if (valuepassword === '') { 
            setError(prevError => ({
                ...prevError,
                password: true
            }));
        }

        if (valueEmail === '' || valuepassword === '') {
            return;
        }

        window.location.href = "/dashboard";
    }

    return (
        <div className='body singlePages'>
            <div className='col-12 px-4' style={{
                    minHeight: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
            }}>
                <form className="col-lg-6 col-xl-4 col-12 mx-auto login-form" onSubmit={onSubmit}>
                    <div className='d-flex flex-column align-items-center my-2 w-100'>
                        <img className='logo-form' src={Logo} alt="#/" />
                        <h1>Welcome</h1>
                        <p>Please login to continue</p>
                    </div>
                    <div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input onChange={(event) => setValueEmail(event?.target.value)} type="email" className={`form-control ${error.email ? 'error':'primary'}`} id="email" placeholder="Enter your email" />
                            {error.email && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input onChange={(event) => setValuepassword (event?.target.value)} type="password" className={`form-control ${error.password ? 'error':'primary'}`} id="password" placeholder="Enter password" />
                            {error.password && <FormHelperText className='danger-help'><ErrorIcon fontSize="inherit" /> Este campo es obligatorio</FormHelperText>}
                        </div>
                        <div className='d-flex align-items-center justify-content-center mt-4'>
                            <button type='submit' className='w-75 btn btn-primary px-1 py-3'>
                                Log in
                            </button>
                        </div>
                        <div className='d-flex justify-content-center fs-6 gap-1 mt-3'>
                            <p>
                                Don’t have an account?
                                <a className='ps-1 color-apple' href="#/">Sign up</a>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;