import React, { useState } from "react";
import {
    Box,
    Tab,
    Tabs 
} from '@mui/material';
import CardSponsor from "../../components/Cards/CardSponsor";

import "../../assets/css/sponsorship/index.css";

// Images Cards
import IconCoke from "../../assets/img/card-sponsor/iconCoke.png";
import IconSamsung from "../../assets/img/card-sponsor/iconSamsung.png";
import IconPepsi from "../../assets/img/card-sponsor/iconPepsi.png";
import IconBaskin from "../../assets/img/card-sponsor/iconBaskin.png";
  
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && <Box sx={{ pt: 3, pb: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SponsorshipPage = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const titles = [
        "Sponsorship offer from Coke",
        "Sponsorship offer from Samsung",
        "Sponsorship offer from Pepsi",
        "Sponsorship offer from Baskin Robbins",
    ];

    const texts = [
        "Polar Ace's OBS extension allows us to display Advertisements, moving images, other live streams in a designated part of a live stream. It can be turned on and off by the creator.",
        "Polar Ace's OBS extension allows us to display Advertisements, moving images, other live streams in a designated part of a live stream. It can be turned on and off by the creator.",
        "Polar Ace's OBS extension allows us to display Advertisements, moving images, other live streams in a designated part of a live stream. It can be turned on and off by the creator.",
        "Polar Ace's OBS extension allows us to display Advertisements, moving images, other live streams in a designated part of a live stream. It can be turned on and off by the creator.",
    ]

    return (
        <section className="section-sponsorship">
            <h1 className="title-dashboard">Sponsorship Offers</h1>

            <div className="col-12">
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs 
                            className="tabs-sponsorship" 
                            value={value} 
                            onChange={handleChange} 
                            aria-label="basic tabs example"
                            variant="scrollable"
                        >
                            <Tab className="tab-sponsorship" label="Newley Offers" {...a11yProps(0)} />
                            <Tab className="tab-sponsorship" label="Accepted" {...a11yProps(1)} />
                            <Tab className="tab-sponsorship" label="Requested More Information" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    
                    <CustomTabPanel value={value} index={0}>
                        <div className="row">
                            <div className="col-md-6 col-12 mb-3">
                                <CardSponsor image={IconCoke} title={titles[0]} text={texts[0]} />
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <CardSponsor image={IconSamsung} title={titles[1]} text={texts[1]} />
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <CardSponsor image={IconPepsi} title={titles[2]} text={texts[2]} />
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <CardSponsor image={IconBaskin} title={titles[3]} text={texts[3]} />
                            </div>
                        </div>
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>
                        <div className="row">
                            <div className="col-md-6 col-12 mb-3">
                                <CardSponsor image={IconCoke} title={titles[0]} text={texts[0]} />
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <CardSponsor image={IconSamsung} title={titles[1]} text={texts[1]} />
                            </div>
                        </div>
                    </CustomTabPanel>
                    
                    <CustomTabPanel value={value} index={2}>
                        <div className="row">
                            <div className="col-md-6 col-12 mb-3">
                                <CardSponsor image={IconCoke} title={titles[0]} text={texts[0]} />
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <CardSponsor image={IconSamsung} title={titles[1]} text={texts[1]} />
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <CardSponsor image={IconPepsi} title={titles[2]} text={texts[2]} />
                            </div>
                        </div>
                    </CustomTabPanel>
                </Box>
            </div>
        </section>
    );
}

export default SponsorshipPage;