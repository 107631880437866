import Icon from '../../assets/img/dashboard/obs-extension/OBSPolarIcon.png';
import '../../assets/css/obs-estension/index.css';


import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';

const ObsExtension = () => {
    return (
        <div className="w-100">
            <div className="obs-estension">
                <h2 className='mt-3 mb-3'>
                    OBS Extension
                </h2>
            </div>
            <div className='d-flex flex-md-row flex-column w-100 m-0 obs-estension__item'>
                <div className='obs-estension__col-items-1'>
                    <img className='w-md-100' src={Icon} alt="#/" />
                </div>
                <div className='obs-estension__col-items-2'>
                    <h3>
                        Download the <span className='color-apple'>Polar Ace OBS Extension</span>
                    </h3>
                    <p className='mb-4 mt-3'>
                        Polar Ace's OBS extension allows us to display Advertisements, moving images, other live streams in a designated part of a live stream. It can be turned on and off by the creator.
                    </p>
                    <Button
                        className='btn btn-primary'
                        startIcon={<DownloadIcon style={{ fontSize: 30 }} />}
                        sx={{
                            backgroundColor: '#60A9ED',
                            color: '#fff',
                        }}>
                        Download Now
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ObsExtension;